import React from "react";
import { StyleSheet, Text, View, Image, ActivityIndicator } from "react-native";
import Button from "../micro/Button";
import { FontAwesome5 } from "@expo/vector-icons";

const Header = (props) => {
  return (
    <View style={styles.header}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: 10,
          paddingVertical: 5,
          paddingHorizontal: 10,
        }}
      >
        <Image source={require("../assets/logo.png")} style={styles.logo} />
        <Text style={styles.headerTitle}>{props.title}</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FontAwesome5 name="calendar-alt" size={24} color="#eee" />
        <Text
          style={{
            color: "#fff",
            fontSize: 14,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {new Date().toDateString()} - {new Date().toLocaleTimeString()}
        </Text>
      </View>
      <View style={styles.accInfo}>
        <ActivityIndicator
          animating={props.loading}
          size="small"
          color="#fff"
        />

        <Text style={styles.accName}>
          {props.accountName}({props.accountRole})
        </Text>
        <Button title={props.btnTitle} onPress={props.onClick} />
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    width: "99%",
    backgroundColor: "#683683",
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between  ",
    height: 70,
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#683683",
  },
  logo: {
    width: 40,
    height: 45,
    resizeMode: "contain",
    marginRight: 20,
  },
  accInfo: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  accName: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#fff",
  },
});
