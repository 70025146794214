import React, { useState } from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNetInfo } from "@react-native-community/netinfo";
import firebase from "../config/firebase";

const Loading = (props) => {
  const [users, setUsers] = useState();
  const db = firebase.firestore().collection("Shops");

  var user;
  var shop = [];

  const network = useNetInfo();

  const aa = () => {
    db.orderBy("branch").onSnapshot(async (querySnapshot) => {
      let shops = [];
      querySnapshot.forEach(async (doc) => {
        shops.push({
          ...doc.data(),
          key: doc.id,
        });
        shop = shops;
        try {
          const jsonValue = JSON.stringify(shops);
          await AsyncStorage.setItem("shop", jsonValue);
        } catch (error) {
          console.log(error);
        }
      });
    });
  };
  //   {
  //     reachabilityUrl: "https://clients3.google.com/generate_204",
  //     reachabilityTest: async (response) => response.status === 204,
  //     reachabilityLongTimeout: 60 * 1000, // 60s
  //     reachabilityShortTimeout: 5 * 1000, // 5s
  //     reachabilityRequestTimeout: 15 * 1000, // 15s
  //   }

  if (
    network &&
    network.isConnected === true &&
    network.isInternetReachable === true
  ) {
    (async () => {
      try {
        const jsonValue = await AsyncStorage.getItem("userData");
        const shopjson = await AsyncStorage.getItem("shop");

        user = JSON.parse(jsonValue);

        shop = JSON.parse(shopjson);
        shopjson !== null ? shop : aa();

        setUsers(jsonValue);
        clearTimeout(gg);
        const gg = setTimeout(() => {
          user == null && jsonValue == null
            ? props.navigation.navigate("Login", { shop })
            : [shop && shop.length > 0] &&
              props.navigation.navigate("Main", { user, shop });
        }, 3000);
      } catch (e) {
        console.log(e);
      }
    })();
  }

  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator animating={true} size={100} color="#683683" />
      <Text
        style={{
          fontSize: 18,
          fontWeight: "bold",
          color: "#683683",
          marginTop: 30,
        }}
      >
        {" "}
        Checking Status ..... Please Wait .{" "}
      </Text>

      <View
        style={{
          backgroundColor: "#dedede",
          width: "50%",
          padding: 10,
          marginTop: 30,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: "black",
          }}
        >
          - Checking Internet ={" "}
          {network && network.isInternetReachable === true ? (
            <Text style={{ color: "green" }}>"Connected"</Text>
          ) : (
            <Text style={{ color: "gray" }}> "Connecting ....." </Text>
          )}
        </Text>

        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: "black",
            marginTop: 30,
          }}
        >
          - Connecting Server ={" "}
          {network && network.isConnected === true ? (
            <Text style={{ color: "green" }}>"Connected"</Text>
          ) : (
            <Text style={{ color: "gray" }}> "Connecting ....." </Text>
          )}
        </Text>

        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: "black",
            marginTop: 30,
          }}
        >
          - Getting Shop List ={" "}
          {[shop && shop.length > 0] ? (
            <Text style={{ color: "green" }}>"Got Cha"</Text>
          ) : (
            <Text style={{ color: "gray" }}> "Connecting ....." </Text>
          )}
        </Text>

        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: "black",
            marginTop: 30,
          }}
        >
          - Getting User Account ={" "}
          {users != null && users != undefined ? (
            <Text style={{ color: "green" }}>"Already Logged In"</Text>
          ) : (
            <Text style={{ color: "gray" }}> "Connecting ....." </Text>
          )}
        </Text>
      </View>
    </View>
  );
};

export default Loading;

const styles = StyleSheet.create({});
