import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";

const Dashboard = (props) => {
  const user = props.user;

  return (
    <View style={styles.dashboard}>
      <Image
        source={require("../assets/logo.png")}
        style={{
          width: 500,
          height: 500,
          resizeMode: "contain",
          marginBottom: 20,
        }}
      />
      <Text style={{ fontSize: 20, fontWeight: "bold", color: "#683683" }}>
        Welcome {user.name}
      </Text>
    </View>
  );
};

export default Dashboard;

const styles = StyleSheet.create({
  dashboard: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
