import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

const MenuItem = (props) => {
  return (
    <TouchableOpacity style={props.styles} onPress={props.onPress}>
      <Text style={styles.btnText}>{props.title}</Text>
    </TouchableOpacity>
  );
};

export default MenuItem;

const styles = StyleSheet.create({
  btnText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#683683",
  },
});
