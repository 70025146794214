import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import firebase from "../config/firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

const LogIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState();

  const db = firebase.firestore().collection("Users");

  const { shop } = props.route.params;

  const login = async () => {
    setLoading(true);
    await db
      .doc(email)
      .get()
      .then(async (document) => {
        let users = document.data();
        console.log(users);
        if (users.password == password) {
          try {
            const aa = JSON.stringify(document.data());
            await AsyncStorage.setItem("userData", aa);
            const jsonValue = await AsyncStorage.getItem("userData");
            const user = JSON.parse(jsonValue);
            clearTimeout(gg);
            const gg = setTimeout(() => {
              setLoading(false);
              props.navigation.navigate("Main", { user, shop });
            }, 3000);
          } catch (error) {
            setLoading(false);
            console.log("AsyncStorage Error : " + error);
          }
        } else {
          setLoading(false);
          seterror("Password Not Match");
        }
      })
      .catch(() => {
        setLoading(false);
        seterror("No User Found");
      });
  };

  return (
    <View style={styles.login}>
      <View style={styles.header}>
        <Text style={styles.headerText}> Sign In</Text>
      </View>
      <View style={styles.body}>
        <View style={styles.card}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#eee",
              marginTop: 30,
              marginBottom: 50,
            }}
          >
            HKB Service Manager
          </Text>
          <View style={styles.inputContainer}>
            <Text style={styles.cardText}>Email</Text>
            <View style={styles.inputRowContainer}>
              <MaterialCommunityIcons
                name="email-multiple"
                size={24}
                color="#683683"
              />
              <TextInput
                placeholder="Enter Email"
                style={styles.input}
                onChangeText={(text) => {
                  setEmail(text);
                }}
              />
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.cardText}>Password</Text>
            <View style={styles.inputRowContainer}>
              <MaterialCommunityIcons
                name="shield-key"
                size={24}
                color="#683683"
              />
              <TextInput
                onKeyPress={(e) => {
                  if (e.nativeEvent.key === "Enter") {
                    const re =
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                    if (re.test(email) === false) {
                      seterror("Invalid Email");
                    } else if (password.length < 6) {
                      seterror("Password Must Be At Least 6");
                    } else {
                      login();
                    }
                  }
                }}
                onChangeText={(text) => {
                  setPassword(text);
                }}
                placeholder="Enter Password"
                style={styles.input}
                secureTextEntry={hidden}
              />
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                onPress={() => {
                  setHidden(!hidden);
                }}
              >
                {hidden == false ? (
                  <Ionicons name="eye-off" size={24} color="#683683" />
                ) : (
                  <Ionicons name="eye" size={24} color="#683683" />
                )}
              </TouchableOpacity>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              const re =
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

              if (re.test(email) === false) {
                seterror("Invalid Email");
              } else if (password.length < 6) {
                seterror("Password Must Be At Least 6");
              } else {
                login();
              }
            }}
            style={styles.btn}
          >
            <Text style={styles.btnText}>Sign In</Text>
          </TouchableOpacity>
          {(loading && (
            <ActivityIndicator
              animating={loading}
              size="small"
              color="#fff"
              style={{ marginTop: 10 }}
            />
          )) ||
            (error && (
              <TouchableOpacity
                style={{
                  backgroundColor: "#eeeeee5f",
                  padding: 2,
                  paddingHorizontal: 5,
                  marginTop: 10,
                  borderRadius: 5,
                }}
                onPress={() => {
                  seterror();
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    color: "#fc0303",
                  }}
                >
                  {error}
                </Text>
              </TouchableOpacity>
            ))}
        </View>
      </View>
    </View>
  );
};

export default LogIn;

const styles = StyleSheet.create({
  login: {
    width: Dimensions.get("window").width,
    flex: 1,
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    width: "99%",
    alignItems: "center",
    height: 70,
    paddingLeft: 20,
    backgroundColor: "#683683",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 40,
    color: "#eee",
  },
  body: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: 400,
    height: 500,
    backgroundColor: "#683683",
    borderRadius: 10,
    shadowColor: "#683683bf",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 5,

    alignItems: "center",
    padding: 10,
  },
  inputContainer: {
    width: "80%",
    marginVertical: 20,
  },
  cardText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#eee",
    marginBottom: 10,
  },
  inputRowContainer: {
    flexDirection: "row",
    backgroundColor: "#eee",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  input: {
    marginLeft: 20,
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontWeight: "bold",
  },
  btn: {
    marginTop: 35,
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: "gray",
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    borderRadius: 5,
    backgroundColor: "#eee",
  },
  btnText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#683683",
  },
});
