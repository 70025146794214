import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import firebase from "../config/firebase";
import { DataTable } from "react-native-paper";
import uuid from "react-native-uuid";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Shop = (props) => {
  const [branch, setBranch] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addShop, setAddShop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [shop, setShop] = useState(null);
  const [editInfo, setEditInfo] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [delPermission, setDelPermission] = useState(false);

  const db = firebase.firestore().collection("Shops");

  const saveShop = async () => {
    const shopId = uuid.v4();
    setSuccess(false);
    setLoading(true);
    try {
      db.doc(shopId)
        .set(
          {
            name: name,
            id: shopId,
            branch: parseInt(branch),
            address: address,
          },
          { merge: true }
        )
        .then(() => {
          setSuccess(true);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getShop = () => {
    db.orderBy("branch").onSnapshot(async (querySnapshot) => {
      let shop = [];
      querySnapshot.forEach((doc) => {
        shop.push({
          ...doc.data(),
          key: doc.id,
        });
      });

      setShop(shop);
    });
  };

  const editShop = async () => {
    setSuccess(false);
    setLoading(true);
    try {
      db.doc(editInfo.id)
        .set(editInfo, { merge: true })
        .then(() => {
          setSuccess(true);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const delShop = async () => {
    setSuccess(false);
    setLoading(true);
    try {
      db.doc(editInfo.id)
        .delete()
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    getShop();
  }, []);

  const aa = async () => {
    try {
      const jsonValue = JSON.stringify(shop);
      await AsyncStorage.setItem("shop", jsonValue);
    } catch (error) {
      console.log("AsyncStorage Error : " + error);
    }
  };

  useEffect(() => {
    aa();
  }, [shop]);

  return (
    <View style={styles.login}>
      <View style={styles.header}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <MaterialCommunityIcons name="home-group" size={34} color="#eee" />
          <Text style={styles.headerText}>Shop Management</Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            setSuccess();
            setAddShop(!addShop);
          }}
          style={styles.btn}
        >
          <Text style={styles.btnText}>{addShop ? "Close" : "Add Shop"}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.body}>
        {addShop ? (
          <View style={styles.card}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#eee",
                marginTop: 30,
                marginBottom: 50,
              }}
            >
              HKB Service Manager
            </Text>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Shop Name</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons name="home" size={24} color="#683683" />
                <TextInput
                  placeholder="Enter Name"
                  style={styles.input}
                  onChangeText={(text) => {
                    setName(text);
                  }}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Branch</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="source-branch-check"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  placeholder="Enter Branch Number"
                  style={styles.input}
                  onChangeText={(text) => {
                    setBranch(text);
                  }}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Phone</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="phone"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  placeholder="Enter Phone Number"
                  style={styles.input}
                  onChangeText={(text) => {
                    setPhone(text);
                  }}
                />
              </View>
            </View>

            <View style={[styles.inputContainer, { marginBottom: 35 }]}>
              <Text style={styles.cardText}>Address</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="map-marker"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  multiline={true}
                  placeholder="Enter Address"
                  style={
                    ([styles.input],
                    { height: 100, flex: 1, padding: 5, paddingHorizontal: 10 })
                  }
                  onChangeText={(text) => {
                    setAddress(text);
                  }}
                />
              </View>
            </View>
            <TouchableOpacity
              onPress={() => {
                setError();
                if (name.length < 1) {
                  setError("Invalid Name");
                } else if (branch.length < 1) {
                  setError("Invalid Branch");
                } else if (address.length < 1) {
                  setError("Invalid Address");
                } else if (phone.length < 1) {
                  setError("Invalid Phone");
                } else {
                  saveShop();
                }
              }}
              style={styles.btn}
            >
              <Text style={styles.btnText}>Save Shop</Text>
            </TouchableOpacity>
            {(loading && (
              <ActivityIndicator
                style={{ marginTop: 10 }}
                animating={loading}
                size="small"
                color="#fff"
              />
            )) ||
              (success && (
                <TouchableOpacity
                  style={{
                    marginTop: 10,
                    backgroundColor: "#03fc8c3f",
                    padding: 2,
                    paddingHorizontal: 5,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setSuccess(false);
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#03fc8c",
                    }}
                  >
                    Success
                  </Text>
                </TouchableOpacity>
              )) ||
              (error && (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#eeeeee5f",
                    padding: 2,
                    paddingHorizontal: 5,
                    marginTop: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setError();
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#fc0303",
                    }}
                  >
                    {error}
                  </Text>
                </TouchableOpacity>
              ))}
          </View>
        ) : null}

        <DataTable>
          <DataTable.Header>
            <DataTable.Title
              style={{ maxWidth: 300, justifyContent: "center" }}
            >
              Shop Name
            </DataTable.Title>
            <DataTable.Title
              style={{ maxWidth: 100, justifyContent: "center" }}
            >
              Branch No.
            </DataTable.Title>
            <DataTable.Title style={{ justifyContent: "center" }}>
              Address
            </DataTable.Title>
            <DataTable.Title style={{ justifyContent: "center" }}>
              Phone
            </DataTable.Title>
            <DataTable.Title
              style={{ maxWidth: 100, justifyContent: "center" }}
            >
              Edit
            </DataTable.Title>
            <DataTable.Title
              style={{ maxWidth: 100, justifyContent: "center" }}
            >
              Delete
            </DataTable.Title>
          </DataTable.Header>
          <ScrollView
            style={{
              width: "100%",
              height: 450,
            }}
            showsVerticalScrollIndicator={false}
          >
            {shop &&
              shop.map((shop) => {
                return (
                  <DataTable.Row key={shop.branch}>
                    <DataTable.Cell style={{ maxWidth: 300 }}>
                      {shop.name}
                    </DataTable.Cell>
                    <DataTable.Cell
                      style={{
                        maxWidth: 100,
                        justifyContent: "center",
                      }}
                    >
                      {shop.branch}
                    </DataTable.Cell>
                    <View
                      style={{
                        flex: 1,
                        paddingLeft: 20,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text numberOfLines={5}>{shop.address}</Text>
                    </View>
                    <DataTable.Cell
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {shop.phone}
                    </DataTable.Cell>
                    <DataTable.Cell
                      onPress={() => {
                        setSuccess(false);
                        setEditInfo(shop);
                        setEditPermission(true);
                      }}
                      style={{
                        maxWidth: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: "#ff6f009f",
                          alignItems: "center",
                          padding: 10,
                          paddingHorizontal: 20,
                          borderRadius: 5,
                        }}
                      >
                        <Text>Edit</Text>
                      </View>
                    </DataTable.Cell>
                    <DataTable.Cell
                      onPress={() => {
                        setSuccess(false);
                        setEditInfo(shop);
                        setDelPermission(true);
                      }}
                      style={{
                        maxWidth: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: "#c702029f",
                          alignItems: "center",
                          padding: 10,
                          paddingHorizontal: 20,
                          borderRadius: 5,
                        }}
                      >
                        <Text>Delete</Text>
                      </View>
                    </DataTable.Cell>
                  </DataTable.Row>
                );
              })}
          </ScrollView>
        </DataTable>
      </View>
      {showEdit && (
        <View style={styles.editModal}>
          <View style={styles.card}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#eee",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Edit Shop
            </Text>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Shop Name</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons name="home" size={24} color="#683683" />
                <TextInput
                  defaultValue={editInfo.name}
                  placeholder="Enter Name"
                  style={styles.input}
                  onChangeText={(text) => {
                    setEditInfo({ ...editInfo, name: text });
                  }}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Branch</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="source-branch-check"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  defaultValue={editInfo.branch}
                  placeholder="Enter Branch Number"
                  style={styles.input}
                  onChangeText={(text) => {
                    setEditInfo({ ...editInfo, branch: parseInt(text) });
                  }}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Phone</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="phone"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  defaultValue={editInfo.phone}
                  placeholder="Enter Phone Number"
                  style={styles.input}
                  onChangeText={(text) => {
                    setEditInfo({ ...editInfo, phone: text });
                  }}
                />
              </View>
            </View>

            <View style={[styles.inputContainer, { marginBottom: 35 }]}>
              <Text style={styles.cardText}>Address</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="map-marker"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  defaultValue={editInfo.address}
                  multiline={true}
                  placeholder="Enter Address"
                  style={
                    ([styles.input],
                    { height: 100, flex: 1, padding: 5, paddingHorizontal: 10 })
                  }
                  onChangeText={(text) => {
                    setEditInfo({ ...editInfo, address: text });
                  }}
                />
              </View>
            </View>
            <TouchableOpacity
              onPress={() => {
                setError();
                if (editInfo.name.length < 1) {
                  setError("Invalid Name");
                } else if (editInfo.branch.length < 1) {
                  setError("Invalid Branch");
                } else if (editInfo.address.length < 1) {
                  setError("Invalid Address");
                } else {
                  editShop();
                }
              }}
              style={styles.btn}
            >
              <Text style={styles.btnText}>Save Shop</Text>
            </TouchableOpacity>
            {(loading && (
              <ActivityIndicator
                style={{ marginTop: 10 }}
                animating={loading}
                size="small"
                color="#fff"
              />
            )) ||
              (success && (
                <TouchableOpacity
                  style={{
                    marginTop: 10,
                    backgroundColor: "#03fc8c3f",
                    padding: 2,
                    paddingHorizontal: 5,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setSuccess(false);
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#03fc8c",
                    }}
                  >
                    Success
                  </Text>
                </TouchableOpacity>
              )) ||
              (error && (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#eeeeee5f",
                    padding: 2,
                    paddingHorizontal: 5,
                    marginTop: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setError();
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#fc0303",
                    }}
                  >
                    {error}
                  </Text>
                </TouchableOpacity>
              ))}
            <TouchableOpacity
              style={{ position: "absolute", right: 10, top: 10 }}
              onPress={() => {
                setShowEdit(false);
              }}
            >
              <MaterialCommunityIcons
                name="close-circle"
                size={24}
                color="#fff"
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {showDelete && (
        <View style={styles.editModal}>
          <View style={styles.card}>
            {success ? (
              <TouchableOpacity
                style={{
                  marginTop: 10,
                  backgroundColor: "#03fc8c3f",
                  padding: 2,
                  paddingHorizontal: 5,
                  borderRadius: 5,
                }}
                onPress={() => {
                  setSuccess(false);
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    color: "#03fc8c",
                  }}
                >
                  Success
                </Text>
              </TouchableOpacity>
            ) : (
              <View style={{ width: "100%", alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#fff",
                    marginBottom: 10,
                  }}
                >
                  Are You Sure to Delete This Shop
                </Text>
                <Text style={{ color: "darkred" }}>
                  ({editInfo.name + " - " + editInfo.branch})
                </Text>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.btn}
                    onPress={() => {
                      setShowDelete(false);
                    }}
                  >
                    <Text>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.btn, { backgroundColor: "darkred" }]}
                    onPress={() => {
                      delShop();
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Delete</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
            {(loading && (
              <ActivityIndicator
                style={{ marginTop: 10 }}
                animating={loading}
                size="small"
                color="#fff"
              />
            )) ||
              (error && (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#eeeeee5f",
                    padding: 2,
                    paddingHorizontal: 5,
                    marginTop: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setError();
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#fc0303",
                    }}
                  >
                    {error}
                  </Text>
                </TouchableOpacity>
              ))}

            <TouchableOpacity
              style={{ position: "absolute", right: 10, top: 10 }}
              onPress={() => {
                setShowDelete(false);
              }}
            >
              <MaterialCommunityIcons
                name="close-circle"
                size={24}
                color="#fff"
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {editPermission && (
        <View style={styles.editModal}>
          <View style={styles.card}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#eee",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Password
            </Text>
            <TextInput
              placeholder="Enter Password"
              style={[
                styles.input,
                { backgroundColor: "white", marginBottom: 10 },
              ]}
              onChangeText={(text) => {
                if (text === "Aung09799998955+$") {
                  setEditPermission(false);
                  setShowEdit(true);
                }
              }}
            />
            <TouchableOpacity
              style={styles.btn}
              onPress={() => {
                setEditPermission(false);
              }}
            >
              <Text>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      {delPermission && (
        <View style={styles.editModal}>
          <View style={styles.card}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#eee",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Password
            </Text>
            <TextInput
              placeholder="Enter Password"
              style={[
                styles.input,
                { backgroundColor: "white", marginBottom: 10 },
              ]}
              onChangeText={(text) => {
                if (text === "Aung09799998955+$") {
                  setDelPermission(false);
                  setShowDelete(true);
                }
              }}
            />
            <TouchableOpacity
              style={styles.btn}
              onPress={() => {
                setDelPermission(false);
              }}
            >
              <Text>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
};

export default Shop;

const styles = StyleSheet.create({
  login: {
    width: "100%",
    alignItems: "center",
  },
  header: {
    borderRadius: 5,
    flexDirection: "row",
    width: "99%",
    alignItems: "center",
    height: 60,
    paddingHorizontal: 20,
    backgroundColor: "#683683",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 10,
    color: "#eee",
  },
  body: {
    width: "100%",
    alignItems: "center",
    marginTop: 10,
  },
  card: {
    width: 400,
    backgroundColor: "#683683",
    borderRadius: 10,
    shadowColor: "#683683bf",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    alignItems: "center",
    padding: 10,
    paddingBottom: 20,
    marginBottom: 20,
  },
  inputContainer: {
    width: "80%",
    marginVertical: 10,
  },
  cardText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#eee",
    marginBottom: 10,
  },
  inputRowContainer: {
    flexDirection: "row",
    backgroundColor: "#eee",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  input: {
    marginLeft: 20,
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontWeight: "bold",
  },
  btn: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: "#6836835f",
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  btnText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#683683",
  },
  editModal: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#0000005f",
    justifyContent: "center",
    alignItems: "center",
  },
});
