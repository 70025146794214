import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  ScrollView,
  Dimensions,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import axios from "axios";
import { DataTable } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import NumberFormat from "react-number-format";
import firebase from "../config/firebase";

const Service = (props) => {
  const shop = props.shop;
  const user = props.user;
  const db = firebase.firestore().collection("Users");
  const [technician, setTechnician] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterDate, setfilterDate] = useState("today");
  const [branch, setBranch] = useState("");
  const [sortByItem, setSortByItem] = useState("date");
  const [refresh, setRefresh] = useState(false);
  const [selectedList, setSelectedList] = useState("All");
  const [service, setService] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [serviceDetail, setServiceDetail] = useState({
    warranty: "Out",
    service_return: "No",
    engineer: "",
    progress: "မပြင်ရသေး",
    condition: "",
    brand: "",
    model: "",
    imei: "",
    color: "",
    error: "",
    remark: "",
    customer: "",
    phone: "",
    due_date: "",
    service_charges: "",
    item: "",
    expense: "",
    paid: "",
    service_reply: "",
    return_date: "",
    return: "false",
  });
  const [addService, setAddService] = useState(false);

  const s1 = useRef();
  const s2 = useRef();
  const s3 = useRef();
  const s4 = useRef();
  const s5 = useRef();
  const s6 = useRef();
  const s7 = useRef();
  const s8 = useRef();
  const s9 = useRef();
  const s10 = useRef();
  const s11 = useRef();
  const s12 = useRef();
  const s13 = useRef();
  const s14 = useRef();
  const s15 = useRef();

  var idService = "";
  if ((service.length + 1).toString().length < 2) {
    idService = "000" + (service.length + 1);
  } else if ((service.length + 1).toString().length < 3) {
    idService = "00" + (service.length + 1);
  } else if ((service.length + 1).toString().length < 4) {
    idService = "0" + (service.length + 1);
  } else {
    idService = service.length + 1;
  }

  const getService = async () => {
    await axios
      .post("https://service-manager.hlakabar.com/api/hkb.php?op=getService", {
        filterDate: filterDate ? filterDate : "today",
      })
      .then(async (res) => {
        console.log(res);
        let service = res.data[0].Service;
        service.forEach((obj) => {
          obj.service_charges = +obj.service_charges;
          obj.expense = +obj.expense;
          obj.paid = +obj.paid;
          obj.remain = +obj.remain;
          obj.profit = +obj.profit;
        });
        var xx;
        if (branch === "all") {
          xx = service.sort((a, b) =>
            a[sortByItem].toString().toUpperCase() <
            b[sortByItem].toString().toUpperCase()
              ? 1
              : -1
          );
        } else {
          const bb = service.filter((gg) => gg.branch === branch.toString());
          if (bb == undefined) {
            setService([]);
          } else {
            xx = bb.sort((a, b) =>
              a[sortByItem].toUpperCase() < b[sortByItem].toUpperCase() ? 1 : -1
            );
          }
        }
        if (selectedList === "All") {
          setService(xx);
        } else if (selectedList === "မပြင်ရသေး") {
          const result = xx.filter((a) => a.progress == "မပြင်ရသေး");
          setService(result);
        } else if (selectedList === "ပြင်နေဆဲ") {
          const result = xx.filter((a) => a.progress == "ပြင်နေဆဲ");
          setService(result);
        } else if (selectedList === "ပြင်ပြီး") {
          const result = xx.filter((a) => a.progress == "ပြင်ပြီး");
          setService(result);
        } else if (selectedList === "ပြင်ရ") {
          const result = xx.filter((a) => a.condition == "ပြင်ရ");
          setService(result);
        } else if (selectedList === "ပြင်မရ") {
          const result = xx.filter((a) => a.condition == "ပြင်မရ");
          setService(result);
        } else if (selectedList === "မပြင်တော့ပါ") {
          const result = xx.filter((a) => a.condition == "မပြင်တော့ပါ");
          setService(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsers = () => {
    db.where("branch", "==", branch)
      .where("role", "==", "technician")
      .get()
      .then((gg) => {
        let users = [];
        gg.forEach((data) => {
          users.push({ ...data.data(), key: data.data().email });
        });
        setTechnician(users);
      });
  };

  useEffect(() => {
    setService([]);
    getUsers();
    getService();
  }, [filterDate, loading, refresh, branch, selectedList]);

  const clearInput = () => {
    setServiceDetail({
      warranty: "Out",
      service_return: "No",
      engineer: "",
      progress: "မပြင်ရသေး",
      condition: "",
      brand: "",
      model: "",
      imei: "",
      color: "",
      error: "",
      remark: "",
      customer: "",
      phone: "",
      due_date: "",
      service_charges: "",
      item: "",
      expense: "",
      paid: "",
      service_reply: "",
      return_date: "",
      return: "false",
    });

    if ((service.length + 1).toString().length < 2) {
      idService = "000" + (service.length + 2);
    } else if ((service.length + 1).toString().length < 3) {
      idService = "00" + (service.length + 2);
    } else if ((service.length + 1).toString().length < 4) {
      idService = "0" + (service.length + 2);
    } else {
      idService = service.length + 2;
    }
  };

  useEffect(() => {
    user != null
      ? user.role === "admin"
        ? setBranch("all")
        : setBranch(user.branch)
      : null;
  }, []);

  function filterIt(arr, searchKey) {
    return arr.filter(function (obj) {
      return Object.keys(obj).some(function (key) {
        return obj[key].toString().toLowerCase().includes(searchKey);
      });
    });
  }

  const addServiceFunc = async (sd) => {
    setLoading(true);
    console.log(sd);
    await axios
      .post(
        "https://service-manager.hlakabar.com/api/hkb.php?op=addService",
        sd
      )
      .then(async (res) => {
        console.log(res.data);
        setLoading(false);
        setAddService(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setAddService(false);
      });
    clearInput();
  };

  const addServiceFuncs = async (sd) => {
    setLoading(true);
    console.log(sd);
    await axios
      .post(
        "https://service-manager.hlakabar.com/api/hkb.php?op=addService",
        sd
      )
      .then(async (res) => {
        console.log(res.data);
        setLoading(false);
        setAddService(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setAddService(false);
      });
  };

  if (user.role !== "technician") {
    return (
      <View style={styles.service}>
        <View style={styles.header}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <MaterialCommunityIcons
              name="cellphone-cog"
              size={24}
              color="#eee"
            />
            <Text style={styles.headerText}>Service Management</Text>
            {loading && (
              <ActivityIndicator
                animating={loading}
                size="small"
                color="#fff"
              />
            )}
            <Picker
              style={styles.input1}
              selectedValue={filterDate}
              onValueChange={(itemValue, itemIndex) => setfilterDate(itemValue)}
            >
              <Picker.Item label="Today" value="today" />
              <Picker.Item label="This Week (sun - sat)" value="tweek" />
              <Picker.Item
                label="This Month (1 - 28,29,30,31) "
                value="tmonth"
              />
              <Picker.Item label="This Year (Jan1 - Dec31) " value="tyear" />
              <Picker.Item label="Last Week (last 7 days)" value="pweek" />
              <Picker.Item label="Last Month (last 30days) " value="pmonth" />
              <Picker.Item label="Last Year (last 365days) " value="pyear" />
              <Picker.Item label="== All Time ==" value="all" />
              <Picker.Item label="January" value="jan" />
              <Picker.Item label="February" value="feb" />
              <Picker.Item label="March" value="mar" />
              <Picker.Item label="April" value="apr" />
              <Picker.Item label="May" value="may" />
              <Picker.Item label="June" value="jun" />
              <Picker.Item label="July" value="jul" />
              <Picker.Item label="August" value="aug" />
              <Picker.Item label="September" value="sep" />
              <Picker.Item label="October" value="oct" />
              <Picker.Item label="November" value="nov" />
              <Picker.Item label="December" value="dec" />
            </Picker>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 12,
                marginLeft: 20,
                color: "#fff",
              }}
            >
              ဆိုင်ခွဲ{" "}
            </Text>
            <Picker
              enabled={user.role === "admin" ? true : false}
              style={styles.input1}
              selectedValue={branch}
              onValueChange={(itemValue, itemIndex) => setBranch(itemValue)}
            >
              <Picker.Item label="All Branch" value="all" />
              {shop &&
                shop.map((shop) => {
                  return (
                    <Picker.Item
                      key={shop.branch}
                      label={"Branch_" + shop.branch}
                      value={shop.branch}
                    />
                  );
                })}
            </Picker>
          </View>
        </View>

        <View style={styles.body}>
          <View style={styles.addItemList}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              Service List
            </Text>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={styles.btn1}
                  onPress={() => {
                    delete serviceDetail.id;
                    serviceDetail.voucher =
                      "HKB_B" +
                      branch +
                      "_" +
                      new Date().toISOString().slice(0, 10) +
                      "_" +
                      idService;
                    serviceDetail.branch = branch;
                    serviceDetail.user = user.name;
                    setAddService(true);
                  }}
                >
                  <Text
                    style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}
                  >
                    Add New
                  </Text>
                </TouchableOpacity>
                <TextInput
                  placeholder="Search Everything"
                  style={styles.input1}
                  onChangeText={(text) => {
                    text === ""
                      ? setRefresh(!refresh)
                      : setService(filterIt(service, text.toLowerCase()));
                  }}
                />
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, marginLeft: 20 }}
                >
                  Sort By{" "}
                </Text>
                <Picker
                  style={styles.input1}
                  selectedValue={sortByItem}
                  onValueChange={(itemValue, itemIndex) =>
                    setSortByItem(itemValue)
                  }
                >
                  <Picker.Item label="Date" value="date" />
                  <Picker.Item label="User" value="user" />
                  <Picker.Item label="Voucher" value="voucher" />
                  <Picker.Item label="Customer" value="customer" />
                  <Picker.Item label="Brand" value="brand" />
                  <Picker.Item label="Due Date" value="due_date" />
                  <Picker.Item label="Return Date" value="return_date" />
                  <Picker.Item label="Warranty" value="warranty" />
                  <Picker.Item label="Progress" value="progress" />
                  <Picker.Item label="Technician" value="engineer" />
                  <Picker.Item label="IMEI" value="imei" />
                  <Picker.Item label="Model" value="model" />
                </Picker>
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, marginLeft: 20 }}
                >
                  Filter Service{" "}
                </Text>
                <Picker
                  style={styles.input1}
                  selectedValue={selectedList}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedList(itemValue)
                  }
                >
                  <Picker.Item label="အားလုံး" value="All" />
                  <Picker.Item label="မပြင်ရသေး" value="မပြင်ရသေး" />
                  <Picker.Item label="ပြင်နေဆဲ" value="ပြင်နေဆဲ" />
                  <Picker.Item label="ပြင်ပြီး" value="ပြင်ပြီး" />
                  <Picker.Item label="ပြင်ရ" value="ပြင်ရ" />
                  <Picker.Item label="ပြင်မရ" value="ပြင်မရ" />
                  <Picker.Item label="မပြင်တော့ပါ" value="မပြင်တော့ပါ" />
                </Picker>
              </View>

              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                စုစုပေါင်းဆားဗစ် = {service.length}
              </Text>

              <TouchableOpacity
                onPress={() => {
                  setRefresh(!refresh);
                }}
              >
                <MaterialCommunityIcons
                  name="refresh"
                  size={24}
                  color="black"
                />
              </TouchableOpacity>
            </View>
            <ScrollView
              horizontal={true}
              contentContainerStyle={{ width: 2500 }}
            >
              <DataTable style={{ flex: 1, width: 2500 }}>
                <DataTable.Header
                  style={{
                    width: 2500,
                    backgroundColor: "#6836838f",
                    marginTop: 5,
                    borderBottomColor: "black",
                    borderWidth: 1,
                    borderBottomWidth: 1,
                    borderColor: "black",
                    paddingHorizontal: 0,
                  }}
                >
                  <DataTable.Title
                    style={[styles.tableTitle, { maxWidth: 50 }]}
                  >
                    စဥ်
                  </DataTable.Title>
                  <DataTable.Title
                    style={[styles.tableTitle, { maxWidth: 50 }]}
                  >
                    ဆိုင်ခွဲ
                  </DataTable.Title>
                  <DataTable.Title style={[styles.tableTitle]}>
                    User
                  </DataTable.Title>
                  <DataTable.Title style={[styles.tableTitle]}>
                    ရက်စွဲ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ဘောက်ချာ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    အမည်
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ဖုန်းနံပါတ်
                  </DataTable.Title>
                  <DataTable.Title style={[styles.tableTitle]}>
                    Brand
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    မော်ဒယ်
                  </DataTable.Title>
                  <DataTable.Title
                    style={[styles.tableTitle, { maxWidth: 70 }]}
                  >
                    IMEI
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    အရောင်
                  </DataTable.Title>
                  <DataTable.Title
                    style={[styles.tableTitle, { maxWidth: 50 }]}
                  >
                    ဝရန်တီ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    Return
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    Error
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    မှတ်ချက်
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ရက်ချိန်း
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    Technician
                  </DataTable.Title>
                  <DataTable.Title
                    style={[styles.tableTitle, { maxWidth: 70 }]}
                  >
                    Progress
                  </DataTable.Title>

                  <DataTable.Title style={styles.tableTitle}>
                    ok/not_ok
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ကျသင့်ငွေ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ပစ္စည်း
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ပစ္စည်းဖိုး
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ပေးငွေ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ကျန်ငွေ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    အမြတ်ငွေ
                  </DataTable.Title>
                  <DataTable.Title style={styles.tableTitle}>
                    ပစ္စည်း Supplier
                  </DataTable.Title>
                  <DataTable.Title
                    style={[styles.tableTitle, { borderRightWidth: 0 }]}
                  >
                    ပြန်ပေးနေ့
                  </DataTable.Title>
                </DataTable.Header>

                {service.length === 0 ? (
                  <View
                    style={{
                      width: Dimensions.get("window").width,
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ActivityIndicator
                      animating={true}
                      size="large"
                      color="#683683"
                    />
                  </View>
                ) : (
                  <ScrollView
                    style={{
                      width: "100%",
                      flex: 1,
                      borderWidth: 1,
                      borderBottomWidth: 0,
                    }}
                    showsVerticalScrollIndicator={false}
                  >
                    {service != [] &&
                      service.map((service, index) => {
                        return (
                          <DataTable.Row
                            key={service.id}
                            onPress={() => {
                              setServiceDetail({
                                ...service,
                                return: "false",
                                pdate: service.date,
                                ppaid: service.paid,
                                pservice_charges: service.service_charges,
                                pexpense: service.expense,
                              }) & setAddService(true);
                            }}
                            style={{
                              borderBottomWidth: 0,
                              width: 2500,
                              paddingHorizontal: 0,
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#dedede",
                            }}
                          >
                            <View style={[styles.tableTitle, { maxWidth: 50 }]}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {index + 1}
                              </Text>
                            </View>
                            <View style={[styles.tableTitle, { maxWidth: 50 }]}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.branch}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.user}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.date}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.voucher}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.customer}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.phone}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.brand}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.model}
                              </Text>
                            </View>
                            <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.imei}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.color}
                              </Text>
                            </View>
                            <View style={[styles.tableTitle, { maxWidth: 50 }]}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.warranty}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.service_return}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.error.toString()}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.remark}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.due_date}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.engineer}
                              </Text>
                            </View>
                            <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.progress}
                              </Text>
                            </View>

                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.condition}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.service_charges}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.item}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.expense}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.paid}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.remain}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.profit}
                              </Text>
                            </View>
                            <View style={styles.tableTitle}>
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.service_reply}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.tableTitle,
                                {
                                  borderRightWidth: 2,
                                  borderRightColor: "white",
                                },
                              ]}
                            >
                              <Text style={styles.dataText} numberOfLines={3}>
                                {service.return_date}
                              </Text>
                            </View>
                          </DataTable.Row>
                        );
                      })}
                  </ScrollView>
                )}
                <DataTable.Row
                  style={{
                    backgroundColor: "#6836838f",
                    borderWidth: 1,
                    width: Dimensions.get("window").width - 50,
                  }}
                >
                  <View style={styles.tableTitle}>
                    <Text style={styles.dataText} numberOfLines={3}>
                      ကျသင့်ငွေ ={" "}
                      {service != [] &&
                        service.reduce((a, b) => {
                          return a + b.service_charges;
                        }, 0)}
                    </Text>
                  </View>
                  <View style={styles.tableTitle}>
                    <Text style={styles.dataText} numberOfLines={3}>
                      ပစ္စည်းဖိုး ={" "}
                      {service != [] &&
                        service.reduce((a, b) => {
                          return a + b.expense;
                        }, 0)}
                    </Text>
                  </View>
                  <View style={styles.tableTitle}>
                    <Text style={styles.dataText} numberOfLines={3}>
                      ပေးငွေ ={" "}
                      {service != [] &&
                        service.reduce((a, b) => {
                          return a + b.paid;
                        }, 0)}
                    </Text>
                  </View>
                  <View style={styles.tableTitle}>
                    <Text style={styles.dataText} numberOfLines={3}>
                      ကျန်ငွေ ={" "}
                      {service != [] &&
                        service.reduce((a, b) => {
                          return a + b.remain;
                        }, 0)}
                    </Text>
                  </View>
                  <View style={[styles.tableTitle, { borderRightWidth: 0 }]}>
                    <Text style={styles.dataText} numberOfLines={3}>
                      အမြတ်ငွေ ={" "}
                      {service != [] &&
                        service.reduce((a, b) => {
                          return a + b.profit;
                        }, 0)}
                    </Text>
                  </View>
                </DataTable.Row>
              </DataTable>
            </ScrollView>
          </View>
        </View>
        {addService && (
          <View style={styles.modalView}>
            <TouchableOpacity
              style={{ right: 20, top: 20, position: "absolute" }}
              onPress={() => {
                clearInput();
                setAddService(false);
              }}
            >
              <MaterialCommunityIcons
                name="close-circle"
                size={24}
                color="#683683"
              />
            </TouchableOpacity>
            <View style={styles.serviceModal}>
              <Text style={{ fontSize: 18, fontWeight: "bold", color: "#fff" }}>
                ဆားဗစ်လက်ခံ
              </Text>
              <ScrollView
                style={{ width: "90%" }}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ alignItems: "center" }}
              >
                <View style={styles.mainContainer}>
                  <Text style={styles.serviceHeader}>Basic Information</Text>
                  <View style={styles.rowContainer}>
                    <View style={styles.columnContainer}>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Brand</Text>
                        <TextInput
                          placeholder="Brand"
                          style={styles.voucherInput}
                          ref={s2}
                          value={serviceDetail.brand ? serviceDetail.brand : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, brand: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Model</Text>
                        <TextInput
                          placeholder="Model"
                          style={styles.voucherInput}
                          ref={s3}
                          value={serviceDetail.model ? serviceDetail.model : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, model: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>IMEI</Text>
                        <TextInput
                          placeholder="IMEI"
                          style={styles.voucherInput}
                          ref={s4}
                          value={serviceDetail.imei ? serviceDetail.imei : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, imei: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Color</Text>
                        <TextInput
                          placeholder="Color"
                          style={styles.voucherInput}
                          ref={s5}
                          value={serviceDetail.color ? serviceDetail.color : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, color: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Error</Text>
                        <TextInput
                          placeholder="Error"
                          style={styles.voucherInput}
                          ref={s6}
                          value={serviceDetail.error ? serviceDetail.error : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, error: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Remark</Text>
                        <TextInput
                          placeholder="Remark"
                          multiline
                          style={[styles.voucherInput, { height: 75 }]}
                          ref={s7}
                          value={
                            serviceDetail.remark ? serviceDetail.remark : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              remark: text,
                            });
                          }}
                        />
                      </View>
                    </View>
                    <View style={styles.columnContainer}>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Voucher ID</Text>
                        <TextInput
                          placeholder="Voucher ID"
                          style={styles.voucherInput}
                          ref={s1}
                          value={
                            serviceDetail.voucher ? serviceDetail.voucher : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              voucher: text,
                            });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Name</Text>
                        <TextInput
                          placeholder="Name"
                          style={styles.voucherInput}
                          ref={s8}
                          value={
                            serviceDetail.customer ? serviceDetail.customer : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              customer: text,
                            });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Phone Number</Text>
                        <TextInput
                          placeholder="Phone Number"
                          style={styles.voucherInput}
                          ref={s9}
                          value={serviceDetail.phone ? serviceDetail.phone : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, phone: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Warranty</Text>
                        <Picker
                          style={styles.voucherInput}
                          selectedValue={serviceDetail.warranty}
                          onValueChange={(itemValue, itemIndex) =>
                            setServiceDetail({
                              ...serviceDetail,
                              warranty: itemValue,
                            })
                          }
                        >
                          <Picker.Item label="Out Warranty" value="Out" />
                          <Picker.Item label="In Warranty" value="In" />
                        </Picker>
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Service Return</Text>
                        <Picker
                          style={styles.voucherInput}
                          selectedValue={serviceDetail.service_return}
                          onValueChange={(itemValue, itemIndex) =>
                            setServiceDetail({
                              ...serviceDetail,
                              service_return: itemValue,
                            })
                          }
                        >
                          <Picker.Item label="No" value="No" />
                          <Picker.Item label="Yes" value="Yes" />
                        </Picker>
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>ရက်ချိန်း</Text>
                        <TextInput
                          placeholder="yyyy-mm-dd"
                          style={styles.voucherInput}
                          ref={s10}
                          value={
                            serviceDetail.due_date ? serviceDetail.due_date : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              due_date: text,
                            });
                          }}
                        />
                      </View>

                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Total Amount</Text>
                        <TextInput
                          placeholder="Total Amount"
                          style={styles.voucherInput}
                          ref={s11}
                          value={
                            serviceDetail.service_charges
                              ? serviceDetail.service_charges
                              : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              service_charges: text,
                            });
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.mainContainer}>
                  <Text style={styles.serviceHeader}>Service Information</Text>
                  <View style={styles.rowContainer}>
                    <View style={styles.columnContainer}>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Technician</Text>
                        <Picker
                          style={styles.voucherInput}
                          selectedValue={serviceDetail.engineer}
                          onValueChange={(itemValue, itemIndex) =>
                            setServiceDetail({
                              ...serviceDetail,
                              engineer: itemValue,
                            })
                          }
                        >
                          <Picker.Item label="Choose Technician" value="" />
                          {technician &&
                            technician.length > 0 &&
                            technician.map((technician) => {
                              return (
                                <Picker.Item
                                  key={technician.key}
                                  label={technician.name}
                                  value={technician.name}
                                />
                              );
                            })}
                        </Picker>
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Progress</Text>
                        <Picker
                          style={styles.voucherInput}
                          selectedValue={serviceDetail.progress}
                          onValueChange={(itemValue, itemIndex) =>
                            setServiceDetail({
                              ...serviceDetail,
                              progress: itemValue,
                            })
                          }
                        >
                          <Picker.Item label="မပြင်ရသေး" value="မပြင်ရသေး" />
                          <Picker.Item label="ပြင်နေဆဲ" value="ပြင်နေဆဲ" />
                          <Picker.Item label="ပြင်ပြီး" value="ပြင်ပြီး" />
                        </Picker>
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Condition</Text>
                        <Picker
                          style={styles.voucherInput}
                          selectedValue={serviceDetail.condition}
                          onValueChange={(itemValue, itemIndex) =>
                            setServiceDetail({
                              ...serviceDetail,
                              condition: itemValue,
                            })
                          }
                        >
                          <Picker.Item label="Choose Condition" value="" />
                          <Picker.Item label="ပြင်ရ" value="ပြင်ရ" />
                          <Picker.Item label="ပြင်မရ" value="ပြင်မရ" />
                          <Picker.Item
                            label="မပြင်တော့ပါ"
                            value="မပြင်တော့ပါ"
                          />
                        </Picker>
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>ပစ္စည်း Supplier</Text>
                        <TextInput
                          placeholder="ပစ္စည်း Supplier"
                          style={[styles.voucherInput, { width: "225%" }]}
                          ref={s13}
                          value={
                            serviceDetail.service_reply
                              ? serviceDetail.service_reply
                              : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              service_reply: text,
                            });
                          }}
                        />
                      </View>
                    </View>
                    <View style={styles.columnContainer}>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Spare Parts</Text>
                        <TextInput
                          placeholder="Spare Parts"
                          style={styles.voucherInput}
                          ref={s12}
                          value={serviceDetail.item ? serviceDetail.item : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, item: text });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Expense</Text>
                        <TextInput
                          placeholder="0"
                          style={styles.voucherInput}
                          ref={s14}
                          value={
                            serviceDetail.expense ? serviceDetail.expense : ""
                          }
                          onChangeText={(text) => {
                            setServiceDetail({
                              ...serviceDetail,
                              expense: text,
                            });
                          }}
                        />
                      </View>
                      <View style={styles.voucherItem}>
                        <Text style={styles.dataText1}>Paid</Text>
                        <TextInput
                          placeholder="0"
                          style={styles.voucherInput}
                          ref={s15}
                          value={serviceDetail.paid ? serviceDetail.paid : ""}
                          onChangeText={(text) => {
                            setServiceDetail({ ...serviceDetail, paid: text });
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.innerModalConfirm}>
                  <TouchableOpacity
                    style={[styles.btn1, { backgroundColor: "gray" }]}
                    onPress={() => {
                      clearInput();
                      setAddService(false);
                    }}
                  >
                    <Text
                      style={[
                        styles.dataText,
                        { fontSize: 10, textAlignVertical: "center" },
                      ]}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>

                  {serviceDetail.id != undefined && (
                    <>
                      {user.role == "admin" && (
                        <TouchableOpacity
                          style={[styles.btn1, { backgroundColor: "red" }]}
                          onPress={() => {
                            serviceDetail.delete = "true";
                            let sd = serviceDetail;
                            addServiceFunc(sd);
                          }}
                        >
                          <Text
                            style={[
                              styles.dataText,
                              { fontSize: 10, textAlignVertical: "center" },
                            ]}
                          >
                            Delete
                          </Text>
                        </TouchableOpacity>
                      )}
                      <TouchableOpacity
                        style={[styles.btn1, { backgroundColor: "orange" }]}
                        onPress={() => {
                          serviceDetail.return = "true";
                          let sd = serviceDetail;
                          addServiceFunc(sd);
                        }}
                      >
                        <Text
                          style={[
                            styles.dataText,
                            {
                              textAlignVertical: "center",
                            },
                          ]}
                        >
                          ရွေးမည်
                        </Text>
                      </TouchableOpacity>
                    </>
                  )}
                  <TouchableOpacity
                    style={[styles.btn1, { backgroundColor: "lightgreen" }]}
                    onPress={() => {
                      let sd = serviceDetail;
                      // sd.error = sd.error.replaceAll("+", "၊");
                      // sd.remark = sd.remark.replaceAll("+", "၊");
                      // sd.due_date = sd.due_date.replaceAll("+", "၊");
                      // sd.service_reply = sd.service_reply.replaceAll("+", "၊");
                      addServiceFunc(sd);
                    }}
                  >
                    <Text
                      style={[
                        styles.dataText,
                        {
                          textAlignVertical: "center",
                        },
                      ]}
                    >
                      Save
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.btn1, { backgroundColor: "lightblue" }]}
                    onPress={() => {
                      let sd = serviceDetail;
                      // sd.error = sd.error.replaceAll("+", "၊");
                      // sd.remark = sd.remark.replaceAll("+", "၊");
                      // sd.due_date = sd.due_date.replaceAll("+", "၊");
                      // sd.service_reply = sd.service_reply.replaceAll("+", "၊");
                      addServiceFunc(sd);
                    }}
                  >
                    <Text
                      style={[
                        styles.dataText,
                        {
                          textAlignVertical: "center",
                        },
                      ]}
                    >
                      {`Save & Print`}
                    </Text>
                  </TouchableOpacity>
                </View>
              </ScrollView>
            </View>
          </View>
        )}
      </View>
    );
  } else {
    const filterService = () => {
      const aa = service;
      if (selectedList === "All") {
        const result = aa.filter(
          (a) => a.engineer == user.name || a.progress == "မပြင်ရသေး"
        );
        setFilterServices(result);
      } else if (selectedList === "မပြင်ရသေး") {
        const result = aa.filter((a) => a.progress == "မပြင်ရသေး");
        setFilterServices(result);
      } else if (selectedList === "ပြင်နေဆဲ") {
        const result = aa.filter(
          (a) =>
            a.progress == "ပြင်နေဆဲ" &&
            a.condition == "" &&
            a.engineer == user.name
        );
        setFilterServices(result);
      } else if (selectedList === "ပြင်ပြီး") {
        const result = aa.filter(
          (a) => a.progress == "ပြင်ပြီး" && a.engineer == user.name
        );
        setFilterServices(result);
      } else if (selectedList === "ပြင်ရ") {
        const result = aa.filter(
          (a) =>
            a.progress == "ပြင်ပြီး" &&
            a.condition == "ပြင်ရ" &&
            a.engineer == user.name
        );
        setFilterServices(result);
      } else if (selectedList === "ပြင်မရ") {
        const result = aa.filter(
          (a) =>
            a.progress == "ပြင်ပြီး" &&
            a.condition == "ပြင်မရ" &&
            a.engineer == user.name
        );
        setFilterServices(result);
      } else if (selectedList === "မပြင်တော့ပါ") {
        const result = aa.filter(
          (a) => a.condition == selectedList && a.engineer == user.name
        );
        setFilterServices(result);
      }
    };

    useEffect(() => {
      filterService();
    }, [selectedList, service]);

    useEffect(() => {
      user != null
        ? user.role === "technician" && setfilterDate("tmonth")
        : null;
    }, []);
    return (
      <View style={styles.service}>
        <View style={styles.header}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <MaterialCommunityIcons
              name="cellphone-cog"
              size={24}
              color="#eee"
            />
            <Text style={styles.headerText}>Service Management</Text>
            {loading && (
              <ActivityIndicator
                animating={loading}
                size="small"
                color="#fff"
              />
            )}
            <Picker
              style={styles.input1}
              selectedValue={filterDate}
              onValueChange={(itemValue, itemIndex) => setfilterDate(itemValue)}
            >
              <Picker.Item label="Today" value="today" />
              <Picker.Item label="This Week (sun - sat)" value="tweek" />
              <Picker.Item
                label="This Month (1 - 28,29,30,31) "
                value="tmonth"
              />
              <Picker.Item label="This Year (Jan1 - Dec31) " value="tyear" />
              <Picker.Item label="Last Week (last 7 days)" value="pweek" />
              <Picker.Item label="Last Month (last 30days) " value="pmonth" />
              <Picker.Item label="Last Year (last 365days) " value="pyear" />
              <Picker.Item label="== All Time ==" value="all" />
              <Picker.Item label="January" value="jan" />
              <Picker.Item label="February" value="feb" />
              <Picker.Item label="March" value="mar" />
              <Picker.Item label="April" value="apr" />
              <Picker.Item label="May" value="may" />
              <Picker.Item label="June" value="jun" />
              <Picker.Item label="July" value="jul" />
              <Picker.Item label="August" value="aug" />
              <Picker.Item label="September" value="sep" />
              <Picker.Item label="October" value="oct" />
              <Picker.Item label="November" value="nov" />
              <Picker.Item label="December" value="dec" />
            </Picker>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 12,
                marginLeft: 20,
                color: "#fff",
              }}
            >
              ဆိုင်ခွဲ{" "}
            </Text>
            <Picker
              enabled={false}
              style={styles.input1}
              selectedValue={branch}
              onValueChange={(itemValue, itemIndex) => setBranch(itemValue)}
            >
              <Picker.Item label="All Branch" value="all" />
              {shop &&
                shop.map((shop) => {
                  return (
                    <Picker.Item
                      key={shop.branch}
                      label={"Branch_" + shop.branch}
                      value={shop.branch}
                    />
                  );
                })}
            </Picker>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.addItemList}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              Service List
            </Text>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextInput
                  placeholder="Search Everything"
                  style={styles.input1}
                  onChangeText={(text) => {
                    text === ""
                      ? setRefresh(!refresh)
                      : setService(filterIt(service, text.toLowerCase()));
                  }}
                />
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, marginLeft: 20 }}
                >
                  Sort By{" "}
                </Text>
                <Picker
                  style={styles.input1}
                  selectedValue={sortByItem}
                  onValueChange={(itemValue, itemIndex) =>
                    setSortByItem(itemValue)
                  }
                >
                  <Picker.Item label="Date" value="date" />
                  <Picker.Item label="User" value="user" />
                  <Picker.Item label="Voucher" value="voucher" />
                  <Picker.Item label="Customer" value="customer" />
                  <Picker.Item label="Brand" value="brand" />
                  <Picker.Item label="Due Date" value="due_date" />
                  <Picker.Item label="Return Date" value="return_date" />
                  <Picker.Item label="Warranty" value="warranty" />
                  <Picker.Item label="Progress" value="progress" />
                  <Picker.Item label="Technician" value="engineer" />
                  <Picker.Item label="IMEI" value="imei" />
                  <Picker.Item label="Model" value="model" />
                </Picker>
              </View>

              <Text
                style={{ fontWeight: "bold", fontSize: 12, marginLeft: 20 }}
              >
                Filter Service{" "}
              </Text>
              <Picker
                style={styles.input1}
                selectedValue={selectedList}
                onValueChange={(itemValue, itemIndex) =>
                  setSelectedList(itemValue)
                }
              >
                <Picker.Item label="အားလုံး" value="All" />
                <Picker.Item label="မပြင်ရသေး" value="မပြင်ရသေး" />
                <Picker.Item label="ပြင်နေဆဲ" value="ပြင်နေဆဲ" />
                <Picker.Item label="ပြင်ပြီး" value="ပြင်ပြီး" />
                <Picker.Item label="ပြင်ရ" value="ပြင်ရ" />
                <Picker.Item label="ပြင်မရ" value="ပြင်မရ" />
                <Picker.Item label="မပြင်တော့ပါ" value="မပြင်တော့ပါ" />
              </Picker>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                စုစုပေါင်းဆားဗစ် = {filterServices.length}
              </Text>

              <TouchableOpacity
                onPress={() => {
                  setRefresh(!refresh);
                }}
              >
                <MaterialCommunityIcons
                  name="refresh"
                  size={24}
                  color="black"
                />
              </TouchableOpacity>
            </View>
            <DataTable style={{ flex: 1 }}>
              <DataTable.Header
                style={{
                  backgroundColor: "#6836838f",
                  marginTop: 5,
                  borderBottomColor: "black",
                  borderWidth: 1,
                  borderBottomWidth: 1,
                  borderColor: "black",
                  paddingHorizontal: 0,
                }}
              >
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 50 }]}>
                  စဥ်
                </DataTable.Title>

                <DataTable.Title style={styles.tableTitle}>
                  ဘောက်ချာ
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 70 }]}>
                  အမည်
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 90 }]}>
                  ဖုန်းနံပါတ်
                </DataTable.Title>

                <DataTable.Title style={[styles.tableTitle, { maxWidth: 100 }]}>
                  မော်ဒယ်
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 70 }]}>
                  IMEI
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 70 }]}>
                  အရောင်
                </DataTable.Title>
                <DataTable.Title style={styles.tableTitle}>
                  Error
                </DataTable.Title>
                <DataTable.Title style={styles.tableTitle}>
                  မှတ်ချက်
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 80 }]}>
                  ရက်ချိန်း
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 70 }]}>
                  Progress
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 70 }]}>
                  Condition
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 80 }]}>
                  Technician
                </DataTable.Title>
                <DataTable.Title style={[styles.tableTitle, { maxWidth: 70 }]}>
                  Action
                </DataTable.Title>
              </DataTable.Header>
              {filterServices.length === 0 ? (
                <View
                  style={{
                    width: Dimensions.get("window").width,
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ActivityIndicator
                    animating={true}
                    size="large"
                    color="#683683"
                  />
                </View>
              ) : (
                <ScrollView
                  style={{
                    width: "100%",
                    flex: 1,
                    borderWidth: 1,
                    borderBottomWidth: 0,
                  }}
                  showsVerticalScrollIndicator={false}
                >
                  {filterServices != [] &&
                    filterServices.map((service, index) => {
                      return (
                        <DataTable.Row
                          key={service.id}
                          style={{
                            borderBottomWidth: 0,
                            paddingHorizontal: 0,
                            backgroundColor:
                              index % 2 === 0 ? "#fff" : "#dedede",
                          }}
                        >
                          <View style={[styles.tableTitle, { maxWidth: 50 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {index + 1}
                            </Text>
                          </View>

                          <View style={styles.tableTitle}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.voucher}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.customer}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 90 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.phone}
                            </Text>
                          </View>

                          <View style={[styles.tableTitle, { maxWidth: 100 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.model}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.imei}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.color}
                            </Text>
                          </View>

                          <View style={styles.tableTitle}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.error}
                            </Text>
                          </View>
                          <View style={styles.tableTitle}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.remark}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 80 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.due_date}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.progress}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 70 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.condition}
                            </Text>
                          </View>
                          <View style={[styles.tableTitle, { maxWidth: 80 }]}>
                            <Text style={styles.dataText} numberOfLines={3}>
                              {service.engineer}
                            </Text>
                          </View>

                          <TouchableOpacity
                            onPress={() => {
                              // setServiceDetail({
                              //   ...service,
                              //   engineer: user.name,
                              //   return: "false",
                              //   pdate: service.date,
                              //   ppaid: service.paid,
                              //   pservice_charges: service.service_charges,
                              //   pexpense: service.expense,
                              // });
                              let sd = Object.assign(serviceDetail, service);
                              sd.return = "false";
                              sd.ppaid = service.paid;
                              sd.pdate = service.date;
                              sd.pservice_charges = service.service_charges;
                              sd.pexpense = service.expense;
                              sd.engineer = user.name;
                              sd.progress = "ပြင်နေဆဲ";

                              addServiceFuncs(sd);
                            }}
                            style={[
                              styles.tableTitle,
                              {
                                borderRightWidth: 2,
                                borderRightColor: "white",
                                maxWidth: 70,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.dataText,
                                {
                                  paddingHorizontal: 10,
                                  paddingVertical: 5,
                                  backgroundColor: "lightblue",
                                  borderRadius: 3,
                                },
                              ]}
                              numberOfLines={3}
                            >
                              ပြင်မည်
                            </Text>
                          </TouchableOpacity>
                        </DataTable.Row>
                      );
                    })}
                </ScrollView>
              )}
              <DataTable.Row
                style={{
                  backgroundColor: "#6836838f",
                  borderWidth: 1,
                  width: Dimensions.get("window").width - 50,
                }}
              >
                <View style={styles.tableTitle}>
                  <Text style={styles.dataText} numberOfLines={3}>
                    ကျသင့်ငွေ ={" "}
                    {filterServices != [] &&
                      filterServices.reduce((a, b) => {
                        return a + b.service_charges;
                      }, 0)}
                  </Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text style={styles.dataText} numberOfLines={3}>
                    ပစ္စည်းဖိုး ={" "}
                    {filterServices != [] &&
                      filterServices.reduce((a, b) => {
                        return a + b.expense;
                      }, 0)}
                  </Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text style={styles.dataText} numberOfLines={3}>
                    ပေးငွေ ={" "}
                    {filterServices != [] &&
                      filterServices.reduce((a, b) => {
                        return a + b.paid;
                      }, 0)}
                  </Text>
                </View>
                <View style={styles.tableTitle}>
                  <Text style={styles.dataText} numberOfLines={3}>
                    ကျန်ငွေ ={" "}
                    {filterServices != [] &&
                      filterServices.reduce((a, b) => {
                        return a + b.remain;
                      }, 0)}
                  </Text>
                </View>
                <View style={[styles.tableTitle, { borderRightWidth: 0 }]}>
                  <Text style={styles.dataText} numberOfLines={3}>
                    အမြတ်ငွေ ={" "}
                    {filterServices != [] &&
                      filterServices.reduce((a, b) => {
                        return a + b.profit;
                      }, 0)}
                  </Text>
                </View>
              </DataTable.Row>
            </DataTable>
          </View>
        </View>
        {serviceDetail && serviceDetail.id != undefined && (
          <View style={styles.modalView}>
            <TouchableOpacity
              style={{ right: 20, top: 20, position: "absolute" }}
              onPress={() => {
                clearInput();
              }}
            >
              <MaterialCommunityIcons
                name="close-circle"
                size={24}
                color="#683683"
              />
            </TouchableOpacity>
            {serviceDetail.finish == true ? (
              <View style={[styles.serviceModal, { width: "50%" }]}>
                <Text style={styles.headerText}>
                  Finishing Job for ( {serviceDetail.model} )
                </Text>
                <Text
                  style={{
                    backgroundColor: "#eee",
                    padding: 2,
                    borderRadius: 1,
                    marginTop: 10,
                    alignSelf: "flex-start",
                    fontSize: 13,
                    color:
                      serviceDetail.condition == "ပြင်ရ"
                        ? "green"
                        : serviceDetail.condition == "ပြင်မရ"
                        ? "red"
                        : "black",
                  }}
                >
                  {" "}
                  Current Status is " {serviceDetail.condition} "
                </Text>
                <View style={styles.voucherItem}>
                  <Text style={styles.dataText1}>
                    Spare Parts / Item / Credit
                  </Text>
                  <TextInput
                    placeholder="Spare Parts / Item / Credit"
                    style={styles.voucherInput}
                    value={serviceDetail.item ? serviceDetail.item : ""}
                    onChangeText={(text) => {
                      setServiceDetail({ ...serviceDetail, item: text });
                    }}
                  />
                </View>
                <View style={styles.voucherItem}>
                  <Text style={styles.dataText1}>ပစ္စည်းဖိုး/ကုန်ကျစရိတ်</Text>
                  <TextInput
                    placeholder="0"
                    style={styles.voucherInput}
                    value={serviceDetail.expense ? serviceDetail.expense : ""}
                    onChangeText={(text) => {
                      setServiceDetail({
                        ...serviceDetail,
                        expense: text,
                      });
                    }}
                  />
                </View>
                <View style={styles.voucherItem}>
                  <Text style={styles.dataText1}>
                    ပစ္စည်းဖိုး + လက်ခ (စုစုပေါင်း)
                  </Text>
                  <TextInput
                    placeholder="Total Amount"
                    style={styles.voucherInput}
                    value={
                      serviceDetail.service_charges
                        ? serviceDetail.service_charges
                        : ""
                    }
                    onChangeText={(text) => {
                      setServiceDetail({
                        ...serviceDetail,
                        service_charges: text,
                      });
                    }}
                  />
                </View>
                <View style={styles.voucherItem}>
                  <Text style={styles.dataText1}>ပစ္စည်း Supplier</Text>
                  <TextInput
                    placeholder="ပစ္စည်း Supplier"
                    style={[styles.voucherInput, { height: 75 }]}
                    multiline
                    value={
                      serviceDetail.service_reply
                        ? serviceDetail.service_reply
                        : ""
                    }
                    onChangeText={(text) => {
                      setServiceDetail({
                        ...serviceDetail,
                        service_reply: text,
                      });
                    }}
                  />
                </View>

                <View style={styles.rowContainer}>
                  <TouchableOpacity
                    style={styles.btn}
                    onPress={() => {
                      clearInput();
                    }}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let sd = serviceDetail;
                      addServiceFunc(sd);
                      clearInput();
                    }}
                    style={[styles.btn, { backgroundColor: "lightgreen" }]}
                  >
                    <Text style={styles.btnText}>Submit</Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <View style={[styles.serviceModal, { backgroundColor: "white" }]}>
                <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                  "{serviceDetail.model}" {"  "} ကို ပြင်ရန် ရွေးထားသည် ။
                </Text>
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    alignSelf: "flex-start",
                  }}
                >
                  အသေးစိတ်အချက်အလက်များ -
                </Text>
                <View style={styles.serviceContainer}>
                  <View style={styles.rowContainer1}>
                    <View style={styles.columnContainer1}>
                      <View style={styles.headContainer}>
                        <Text style={styles.headerText}>Title</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>Brand</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>Model</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>Error</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>Remark</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>အမည်</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>ဖုန်းနံပါတ်</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>IMEI</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>အရောင်</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>လက်ခံသည့်နေ့</Text>
                      </View>
                      <View style={styles.childContainer}>
                        <Text style={styles.containerText}>ရက်ချိန်း</Text>
                      </View>
                    </View>
                    <View style={styles.columnContainer2}>
                      <View style={styles.headContainer}>
                        <Text style={styles.headerText}>Description</Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.brand}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.model}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.error}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.remark}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.customer}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.phone}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.imei}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.color}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.date}
                        </Text>
                      </View>
                      <View style={styles.childContainer1}>
                        <Text style={styles.containerText}>
                          {serviceDetail.due_date}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.rowContainer1}>
                  <TouchableOpacity
                    onPress={() => {
                      setServiceDetail({
                        ...serviceDetail,
                        progress: "ပြင်ပြီး",
                        condition: "ပြင်ရ",
                        finish: true,
                      });
                      // let sd = {
                      //   ...serviceDetail,
                      //   progress: "ပြင်ပြီး",
                      //   condition: "ပြင်ရ",
                      // };
                      // addServiceFunc(sd);
                      // clearInput();
                    }}
                    style={[styles.containerBtn, { backgroundColor: "green" }]}
                  >
                    <Text style={styles.dataText1}>ပြင်ပြီးပါပြီ</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setServiceDetail({
                        ...serviceDetail,
                        progress: "ပြင်ပြီး",
                        condition: "ပြင်မရ",
                        finish: true,
                      });
                      // let sd = {
                      //   ...serviceDetail,
                      //   progress: "ပြင်ပြီး",
                      //   condition: "ပြင်မရ",
                      // };
                      // addServiceFunc(sd);
                      // clearInput();
                    }}
                    style={[styles.containerBtn, { backgroundColor: "red" }]}
                  >
                    <Text style={styles.dataText1}>ပြင်မရပါ</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setServiceDetail({
                        ...serviceDetail,
                        progress: "ပြင်ပြီး",
                        condition: "မပြင်တော့ပါ",
                        finish: true,
                      });
                      // let sd = {
                      //   ...serviceDetail,
                      //   progress: "ပြင်ပြီး",
                      //   condition: "မပြင်တော့ပါ",
                      // };
                      // addServiceFunc(sd);
                      // clearInput();
                    }}
                    style={[styles.containerBtn, { backgroundColor: "blue" }]}
                  >
                    <Text style={styles.dataText1}>မပြင်တော့ပါ</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
};

export default Service;

const styles = StyleSheet.create({
  containerBtn: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 3,
    marginHorizontal: 10,
  },

  containerText: {
    fontSize: 12,
    textAlign: "left",
  },

  serviceContainer: {
    width: "100%",
    marginTop: 20,
    marginBottom: 10,
    borderColor: "gray",
    borderWidth: 2,
    borderRadius: 2,
  },
  rowContainer1: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  columnContainer1: {
    flex: 1,
  },
  columnContainer2: {
    flex: 2,
    borderLeftColor: "gray",
    borderLeftWidth: 2,
  },
  headContainer: {
    backgroundColor: "#683683",
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  childContainer: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderColor: "gray",
    borderWidth: 1,
    borderBottomWidth: 0,
    height: 35,
    backgroundColor: "#cccccc",
  },
  childContainer1: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderColor: "gray",
    borderWidth: 1,
    borderBottomWidth: 0,
    height: 35,
    backgroundColor: "#cccccc",
  },

  serviceHeader: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#fff",
    marginLeft: 10,
    marginTop: 10,
  },

  voucherItem: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginVertical: 5,
    padding: 5,
  },
  voucherInput: {
    paddingVertical: 4,
    paddingHorizontal: 10,
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: 4,
    fontSize: 12,
    textAlign: "right",
    fontWeight: "bold",
    marginTop: 5,
  },

  mainContainer: {
    width: "100%",
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 5,
    marginVertical: 5,
  },

  rowContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  columnContainer: {
    width: "45%",
  },
  innerModalConfirm: {
    marginTop: 20,
    paddingVertical: 20,
    paddingHorizontal: 10,
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: 10,
  },
  modalView: {
    position: "absolute",
    backgroundColor: "#000000ee",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  serviceModal: {
    paddingVertical: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#6836838f",
    width: "90%",
    borderRadius: 10,
    padding: 10,
    height: 600,
  },
  innerModalConfirm: {
    width: 550,
    marginTop: 20,
    height: 75,
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: 10,
  },
  service: {
    width: "100%",
    height: Dimensions.get("window").height - 75,
    alignItems: "center",
  },
  header: {
    borderRadius: 5,
    flexDirection: "row",
    width: "99%",
    alignItems: "center",
    height: 60,
    paddingHorizontal: 20,
    backgroundColor: "#683683",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
    marginHorizontal: 10,
    color: "#eee",
  },
  body: {
    width: "100%",
    alignItems: "center",
    marginTop: 10,
    flex: 1,
  },
  btn: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: "#fff",
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    borderRadius: 5,
    backgroundColor: "#fff",
    marginHorizontal: 10,
  },
  btn1: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: "gray",
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    borderRadius: 5,
    backgroundColor: "#683683",
    marginVertical: 10,
  },

  btnText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#683683",
  },
  addContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  addItemList: {
    width: "100%",
    flex: 3,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ababab",
    borderRadius: 5,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  addItemDetail: {
    flex: 1,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ababab",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  input1: {
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderWidth: 1.5,
    marginLeft: 10,
    borderRadius: 4,
    width: 200,
    fontSize: 12,
  },
  tableTitle: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  dataText: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontWeight: "bold",
    textAlignVertical: "center",
  },
  dataText1: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontWeight: "bold",
    textAlignVertical: "center",
    color: "white",
  },
});
