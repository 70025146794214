import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import LeftMenu from "../micro/LeftMenu";
import RightPane from "../micro/RightPane";

const Body = (props) => {
  const [chosen, setChosen] = useState("Dashboard");
  const user = props.user;

  return (
    <View style={styles.body}>
      <LeftMenu
        menus={
          user.role === "admin"
            ? ["Dashboard", "Service", "CashBook", "Inventory", "Shop", "Users"]
            : user.role === "technician"
            ? ["Dashboard", "Service"]
            : ["Dashboard", "Service", "CashBook", "Inventory"]
        }
        stateChanger={setChosen}
        accountRole={props.accountRole}
        accountName={props.accountName}
        title="HKB Service Manager"
        btnTitle={props.btnTitle}
        loading={props.loading}
        onClick={props.onClick}
      />
      <RightPane data={chosen} user={props.user} shop={props.shop} />
    </View>
  );
};

export default Body;

const styles = StyleSheet.create({
  body: {
    width: "100%",
    flex: 1,
  },
});
