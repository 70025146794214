import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

const Button = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.btn}>
      <Text style={styles.btnText}>{props.title}</Text>
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  btn: {
    marginHorizontal: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: "gray",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    borderRadius: 5,
    backgroundColor: "#fefefe",
  },
  btnText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#683683",
  },
});
