import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import {
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import firebase from "../config/firebase";
import { Picker } from "@react-native-picker/picker";
import { DataTable } from "react-native-paper";

const Users = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);
  const [selectedRole, setSelectedRole] = useState("admin");
  const [selectedShop, setSelectedShop] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [user, setuser] = useState(null);
  // const [shop, setShop] = useState(null);
  const [editInfo, setEditInfo] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const db = firebase.firestore().collection("Users");
  const shopDb = firebase.firestore().collection("Shops");
  const [showPassword, setShowPassword] = useState(false);

  const saveUser = async () => {
    setSuccess(false);
    setLoading(true);

    try {
      db.doc(email)
        .set(
          {
            name: name,
            email: email,
            phone: phone,
            role: selectedRole,
            branch: selectedShop,
            password: password,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          var errorMessage = error.message;
          setError(errorMessage);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const editUser = async () => {
    setSuccess(false);
    setLoading(true);
    try {
      db.doc(editInfo.email)
        .set(editInfo, { merge: true })
        .then(() => {
          setSuccess(true);
          setLoading(false);
          setShowEdit(false);
        })
        .catch((error) => {
          var errorMessage = error.message;
          setError(errorMessage);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const shop = props.shop;

  // const getShop = () => {
  //   shopDb.orderBy("branch").onSnapshot((querySnapshot) => {
  //     let shop = [];
  //     querySnapshot.forEach((doc) => {
  //       shop.push({
  //         ...doc.data(),
  //         key: doc.id,
  //       });
  //     });
  //     setShop(shop);
  //   });
  // };

  const getUser = () => {
    db.orderBy("branch").onSnapshot(
      { includeMetadataChanges: true },
      (querySnapshot) => {
        let user = [];
        querySnapshot.forEach((doc) => {
          if (!doc.metadata.hasPendingWrites) {
            user.push({
              ...doc.data(),
              key: doc.id,
            });
          }
        });
        setuser(user);
      }
    );
  };

  console.log(shop);

  const delUser = async () => {
    setSuccess(false);
    setLoading(true);
    try {
      db.doc(editInfo.email)
        .delete()
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    // getShop();
    getUser();
  }, []);

  return (
    <View style={styles.login}>
      <ScrollView
        style={{ width: "100%" }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <View style={styles.header}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome5 name="users" size={24} color="#eee" />
            <Text style={styles.headerText}>Users Management</Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              setSuccess(false);
              setAddUser(!addUser);
            }}
            style={styles.btn}
          >
            <Text style={styles.btnText}>{addUser ? "Close" : "Add User"}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.body}>
          {addUser ? (
            <View style={styles.card}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#eee",
                  marginTop: 30,
                  marginBottom: 50,
                }}
              >
                HKB Service Manager
              </Text>

              <View style={styles.inputContainer}>
                <Text style={styles.cardText}>Account Level</Text>
                <View style={styles.inputRowContainer}>
                  <MaterialIcons
                    name="admin-panel-settings"
                    size={24}
                    color="#683683"
                  />
                  <Picker
                    style={styles.input}
                    selectedValue={selectedRole}
                    onValueChange={(itemValue, itemIndex) =>
                      setSelectedRole(itemValue)
                    }
                  >
                    <Picker.Item label="Admin" value="admin" />
                    <Picker.Item label="Reception" value="reception" />
                    <Picker.Item label="Technician" value="technician" />
                  </Picker>
                </View>
              </View>

              {selectedRole != "admin" && (
                <View style={styles.inputContainer}>
                  <Text style={styles.cardText}>Work Branch</Text>
                  <View style={styles.inputRowContainer}>
                    <MaterialIcons name="work" size={24} color="#683683" />
                    <Picker
                      style={styles.input}
                      selectedValue={selectedShop}
                      onValueChange={(itemValue, itemIndex) =>
                        setSelectedShop(itemValue)
                      }
                    >
                      <Picker.Item label="Select Branch" value="" />
                      {shop &&
                        shop.map((shop) => {
                          return (
                            <Picker.Item
                              key={shop.key}
                              label={`Branch-${shop.branch}`}
                              value={shop.branch}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                </View>
              )}

              <View style={styles.inputContainer}>
                <Text style={styles.cardText}>Name</Text>
                <View style={styles.inputRowContainer}>
                  <MaterialCommunityIcons
                    name="account"
                    size={24}
                    color="#683683"
                  />
                  <TextInput
                    placeholder="Enter Name"
                    style={styles.input}
                    onChangeText={(text) => {
                      setName(text);
                    }}
                  />
                </View>
              </View>

              <View style={styles.inputContainer}>
                <Text style={styles.cardText}>Phone</Text>
                <View style={styles.inputRowContainer}>
                  <MaterialCommunityIcons
                    name="phone"
                    size={24}
                    color="#683683"
                  />
                  <TextInput
                    placeholder="Enter Phone"
                    style={styles.input}
                    onChangeText={(text) => {
                      setPhone(text);
                    }}
                  />
                </View>
              </View>

              <View style={styles.inputContainer}>
                <Text style={styles.cardText}>Email</Text>
                <View style={styles.inputRowContainer}>
                  <MaterialCommunityIcons
                    name="email"
                    size={24}
                    color="#683683"
                  />
                  <TextInput
                    placeholder="Enter Email"
                    style={styles.input}
                    onChangeText={(text) => {
                      setEmail(text);
                    }}
                  />
                </View>
              </View>

              <View style={[styles.inputContainer, { marginBottom: 35 }]}>
                <Text style={styles.cardText}>Password</Text>
                <View style={styles.inputRowContainer}>
                  <MaterialCommunityIcons
                    name="key"
                    size={24}
                    color="#683683"
                  />
                  <TextInput
                    placeholder="Enter Password"
                    style={styles.input}
                    secureTextEntry={hidden}
                    onChangeText={(text) => {
                      setPassword(text);
                    }}
                  />
                  <TouchableOpacity
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      setHidden(!hidden);
                    }}
                  >
                    {hidden == false ? (
                      <Ionicons name="eye-off" size={24} color="#683683" />
                    ) : (
                      <Ionicons name="eye" size={24} color="#683683" />
                    )}
                  </TouchableOpacity>
                </View>
              </View>
              <TouchableOpacity
                onPress={() => {
                  setError();
                  if (name.length < 1) {
                    setError("Invalid Name");
                  } else if (phone.length < 1) {
                    setError("Invalid Phone");
                  } else if (
                    selectedRole != "admin" &&
                    selectedShop.length < 1
                  ) {
                    setError("Invalid Branch");
                  } else if (user.some((e) => e.email === email)) {
                    setError("Email Already Existed");
                  } else {
                    saveUser();
                  }
                }}
                style={styles.btn}
              >
                <Text style={styles.btnText}>Save User</Text>
              </TouchableOpacity>
              {(loading && (
                <ActivityIndicator
                  style={{ marginTop: 10 }}
                  animating={loading}
                  size="small"
                  color="#fff"
                />
              )) ||
                (success && (
                  <TouchableOpacity
                    style={{
                      marginTop: 10,
                      backgroundColor: "#03fc8c3f",
                      padding: 2,
                      paddingHorizontal: 5,
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      setSuccess(false);
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        color: "#03fc8c",
                      }}
                    >
                      Success
                    </Text>
                  </TouchableOpacity>
                )) ||
                (error && (
                  <TouchableOpacity
                    style={{
                      backgroundColor: "#eeeeee5f",
                      padding: 2,
                      paddingHorizontal: 5,
                      marginTop: 10,
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      setError();
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        color: "#fc0303",
                      }}
                    >
                      {error}
                    </Text>
                  </TouchableOpacity>
                ))}
            </View>
          ) : null}
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Branch</DataTable.Title>
              <DataTable.Title>Email</DataTable.Title>
              <DataTable.Title>Phone</DataTable.Title>
              <DataTable.Title>Role</DataTable.Title>

              <DataTable.Title
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                Password
              </DataTable.Title>
              <DataTable.Title>Created At</DataTable.Title>
              <DataTable.Title
                style={{ maxWidth: 100, justifyContent: "center" }}
              >
                Edit
              </DataTable.Title>
              <DataTable.Title
                style={{ maxWidth: 100, justifyContent: "center" }}
              >
                Delete
              </DataTable.Title>
            </DataTable.Header>
            <ScrollView
              style={{
                width: "100%",
                height: 450,
              }}
              showsVerticalScrollIndicator={false}
            >
              {user &&
                user.map((user, index) => {
                  return (
                    <DataTable.Row key={user.key}>
                      <DataTable.Cell>{user.name}</DataTable.Cell>
                      <DataTable.Cell>{user.branch}</DataTable.Cell>
                      <DataTable.Cell>{user.email}</DataTable.Cell>
                      <DataTable.Cell>{user.phone}</DataTable.Cell>
                      <DataTable.Cell>{user.role}</DataTable.Cell>

                      <DataTable.Cell
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onPress={() => {
                          showPassword === false
                            ? setShowPassword(index)
                            : setShowPassword(false);
                        }}
                      >
                        {showPassword === index
                          ? user.password
                          : "*".repeat(user.password.length)}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        {user.createdAt.toDate().toDateString()}
                      </DataTable.Cell>
                      <DataTable.Cell
                        onPress={() => {
                          setSuccess(false);
                          setEditInfo(user);
                          setShowEdit(true);
                        }}
                        style={{
                          maxWidth: 100,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#ff6f009f",
                            alignItems: "center",
                            padding: 10,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                          }}
                        >
                          <Text>Edit</Text>
                        </View>
                      </DataTable.Cell>
                      <DataTable.Cell
                        onPress={() => {
                          setSuccess(false);
                          setEditInfo(user);
                          setShowDelete(true);
                        }}
                        style={{
                          maxWidth: 100,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#c702029f",
                            alignItems: "center",
                            padding: 10,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                          }}
                        >
                          <Text>Delete</Text>
                        </View>
                      </DataTable.Cell>
                    </DataTable.Row>
                  );
                })}
            </ScrollView>
          </DataTable>
        </View>
      </ScrollView>
      {showEdit && (
        <View style={styles.editModal}>
          <View style={styles.card}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#eee",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Edit User
            </Text>
            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Account Level</Text>
              <View style={styles.inputRowContainer}>
                <MaterialIcons
                  name="admin-panel-settings"
                  size={24}
                  color="#683683"
                />
                <Picker
                  style={styles.input}
                  selectedValue={editInfo.role}
                  onValueChange={(itemValue, itemIndex) =>
                    setEditInfo({ ...editInfo, role: itemValue })
                  }
                >
                  <Picker.Item label="Admin" value="admin" />
                  <Picker.Item label="Reception" value="reception" />
                  <Picker.Item label="Technician" value="technician" />
                </Picker>
              </View>
            </View>

            {editInfo.role != "admin" && (
              <View style={styles.inputContainer}>
                <Text style={styles.cardText}>Work Branch</Text>
                <View style={styles.inputRowContainer}>
                  <MaterialIcons name="work" size={24} color="#683683" />
                  <Picker
                    style={styles.input}
                    selectedValue={editInfo.branch}
                    onValueChange={(itemValue, itemIndex) =>
                      setEditInfo({ ...editInfo, branch: itemValue })
                    }
                  >
                    <Picker.Item label="Select Branch" value="" />
                    {shop &&
                      shop.map((shop) => {
                        return (
                          <Picker.Item
                            key={shop.key}
                            label={`Branch-${shop.branch}`}
                            value={shop.branch}
                          />
                        );
                      })}
                  </Picker>
                </View>
              </View>
            )}

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Name</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="account"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  defaultValue={editInfo.name}
                  placeholder="Enter Name"
                  style={styles.input}
                  onChangeText={(text) => {
                    setEditInfo({ ...editInfo, name: text });
                  }}
                />
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.cardText}>Phone</Text>
              <View style={styles.inputRowContainer}>
                <MaterialCommunityIcons
                  name="phone"
                  size={24}
                  color="#683683"
                />
                <TextInput
                  defaultValue={editInfo.phone}
                  placeholder="Enter Phone"
                  style={styles.input}
                  onChangeText={(text) => {
                    setEditInfo({ ...editInfo, phone: text });
                  }}
                />
              </View>
            </View>

            <TouchableOpacity
              onPress={() => {
                setError();
                if (editInfo.name.length < 1) {
                  setError("Invalid Name");
                } else if (editInfo.phone.length < 1) {
                  setError("Invalid Phone");
                } else if (
                  editInfo.role != "admin" &&
                  editInfo.branch.length < 1
                ) {
                  setError("Invalid Branch");
                } else {
                  editUser();
                }
              }}
              style={styles.btn}
            >
              <Text style={styles.btnText}>Save User</Text>
            </TouchableOpacity>
            {(loading && (
              <ActivityIndicator
                style={{ marginTop: 10 }}
                animating={loading}
                size="small"
                color="#fff"
              />
            )) ||
              (success && (
                <TouchableOpacity
                  style={{
                    marginTop: 10,
                    backgroundColor: "#03fc8c3f",
                    padding: 2,
                    paddingHorizontal: 5,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setSuccess(false);
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#03fc8c",
                    }}
                  >
                    Success
                  </Text>
                </TouchableOpacity>
              )) ||
              (error && (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#eeeeee5f",
                    padding: 2,
                    paddingHorizontal: 5,
                    marginTop: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setError();
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#fc0303",
                    }}
                  >
                    {error}
                  </Text>
                </TouchableOpacity>
              ))}
            <TouchableOpacity
              style={{ position: "absolute", right: 10, top: 10 }}
              onPress={() => {
                setShowEdit(false);
              }}
            >
              <MaterialCommunityIcons
                name="close-circle"
                size={24}
                color="#fff"
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {showDelete && (
        <View style={styles.editModal}>
          <View style={styles.card}>
            {success ? (
              <TouchableOpacity
                style={{
                  marginTop: 10,
                  backgroundColor: "#03fc8c3f",
                  padding: 2,
                  paddingHorizontal: 5,
                  borderRadius: 5,
                }}
                onPress={() => {
                  setSuccess(false);
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    color: "#03fc8c",
                  }}
                >
                  Success
                </Text>
              </TouchableOpacity>
            ) : (
              <View style={{ width: "100%", alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#fff",
                    marginBottom: 10,
                  }}
                >
                  Are You Sure to Delete This User
                  <Text style={{ color: "darkred" }}> ({editInfo.name})</Text>
                </Text>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.btn}
                    onPress={() => {
                      setShowDelete(false);
                    }}
                  >
                    <Text>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.btn, { backgroundColor: "darkred" }]}
                    onPress={() => {
                      delUser();
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Delete</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
            {(loading && (
              <ActivityIndicator
                style={{ marginTop: 10 }}
                animating={loading}
                size="small"
                color="#fff"
              />
            )) ||
              (error && (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#eeeeee5f",
                    padding: 2,
                    paddingHorizontal: 5,
                    marginTop: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setError();
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#fc0303",
                    }}
                  >
                    {error}
                  </Text>
                </TouchableOpacity>
              ))}

            <TouchableOpacity
              style={{ position: "absolute", right: 10, top: 10 }}
              onPress={() => {
                setShowDelete(false);
              }}
            >
              <MaterialCommunityIcons
                name="close-circle"
                size={24}
                color="#fff"
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
};

export default Users;

const styles = StyleSheet.create({
  login: {
    width: "100%",
    alignItems: "center",
  },
  header: {
    borderRadius: 5,
    flexDirection: "row",
    width: "99%",
    alignItems: "center",
    height: 60,
    paddingHorizontal: 20,
    backgroundColor: "#683683",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 10,
    color: "#eee",
  },
  body: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  card: {
    width: 400,
    backgroundColor: "#683683",
    borderRadius: 10,
    shadowColor: "#683683bf",
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    alignItems: "center",
    padding: 10,
    paddingBottom: 20,
  },
  inputContainer: {
    width: "80%",
    marginVertical: 10,
  },
  cardText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#eee",
    marginBottom: 10,
  },
  inputRowContainer: {
    flexDirection: "row",
    backgroundColor: "#eee",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  input: {
    marginLeft: 20,
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontWeight: "bold",
  },
  btn: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    shadowColor: "gray",
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  btnText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#683683",
  },
  editModal: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#0000005f",
    justifyContent: "center",
    alignItems: "center",
  },
});
