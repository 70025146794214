import React, { useState } from "react";
import { StyleSheet, View, Dimensions } from "react-native";

import Body from "../components/Body";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Main = (props) => {
  const [loading, setLoading] = useState(false);

  const { user, shop } = props.route.params;

  const signOut = async () => {
    setLoading(true);
    try {
      await AsyncStorage.removeItem("userData");
      await AsyncStorage.removeItem("shop");
      await AsyncStorage.removeItem("item");
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setLoading(false);
      props.navigation.reset({
        index: 1,
        routes: [
          {
            name: "Loading",
          },
        ],
      });
    }, 3000);
  };

  return (
    <View style={styles.container}>
      <Body
        accountRole={user ? user.role : "Not In Service"}
        accountName={user ? user.name : "Guest"}
        user={user ? user : null}
        shop={shop ? shop : null}
        title="HKB Service Manager"
        btnTitle={user ? "Sign Out" : "Sign In"}
        loading={loading}
        onClick={() => {
          if (!user) {
            props.navigation.reset({
              index: 0,
              routes: [
                {
                  name: "Login",
                },
              ],
            });
          } else {
            signOut();
          }
        }}
      />
    </View>
  );
};

export default Main;

const styles = StyleSheet.create({
  container: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    alignItems: "center",
    backgroundColor: "white",
    paddingHorizontal: 5,
    paddingBottom: 5,
  },
});
