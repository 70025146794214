import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// import "firebase/database";
// import "firebase/functions";
// import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAhKstMYdkk1rzZTz20Pk3C4WyOX9L5ELQ",
  authDomain: "hlakabar-company.firebaseapp.com",
  projectId: "hlakabar-company",
  storageBucket: "hlakabar-company.appspot.com",
  messagingSenderId: "268160797429",
  appId: "1:268160797429:web:b39348919a8775bb5153bc",
  measurementId: "G-71QC0259LR",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
