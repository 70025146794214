import React from "react";
import { StyleSheet, View, Text } from "react-native";

import Service from "../Screens/Service";
import Inventory from "../Screens/Inventory";
import Users from "../Screens/Users";
import Dashboard from "../Screens/Dashboard";
import CashBook from "../Screens/CashBook";
import Shop from "../Screens/Shop";

const RightPane = (props) => {
  const DataScreen = `${props.data}`;

  return (
    <View style={styles.rightPane}>
      {DataScreen == "Dashboard" ? (
        <Dashboard user={props.user} shop={props.shop} />
      ) : DataScreen == "Service" ? (
        <Service user={props.user} shop={props.shop} />
      ) : DataScreen == "Inventory" ? (
        <Inventory user={props.user} shop={props.shop} />
      ) : DataScreen == "CashBook" ? (
        <CashBook user={props.user} shop={props.shop} />
      ) : DataScreen == "Shop" ? (
        <Shop />
      ) : (
        <Users shop={props.shop} />
      )}
    </View>
  );
};

export default RightPane;

const styles = StyleSheet.create({
  rightPane: {
    flex: 1,
    padding: 10,
  },
});
