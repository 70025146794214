import React, { useState } from "react";
import { StyleSheet, View, Image, Text, ActivityIndicator } from "react-native";
import Button from "./Button";
import { FontAwesome5 } from "@expo/vector-icons";
import MenuItem from "./MenuItem";

const LeftMenu = (props) => {
  const [chosen, setChosen] = useState("Dashboard");

  const menus = props.menus;
  const stateChanger = props.stateChanger;

  return (
    <View style={styles.menuContainer}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image source={require("../assets/logo.png")} style={styles.logo} />
        <Text style={styles.headerTitle}>{props.title}</Text>

        {menus &&
          menus.map((menu) => {
            return (
              <MenuItem
                key={menu}
                title={menu}
                styles={
                  chosen == menu
                    ? [styles.btn, { backgroundColor: "#ffffff" }]
                    : styles.btn
                }
                onPress={() => {
                  stateChanger(menu);
                  setChosen(menu);
                }}
              />
            );
          })}

        <FontAwesome5 name="calendar-alt" size={24} color="#fff" />
        <Text
          style={{
            color: "#fff",
            fontSize: 14,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {new Date().toDateString()} - {new Date().toLocaleTimeString()}
        </Text>
      </View>
      <View style={styles.accInfo}>
        <ActivityIndicator
          animating={props.loading}
          size="small"
          color="#fff"
        />

        <Text style={styles.accName}>
          {props.accountName}({props.accountRole})
        </Text>
        <Button title={props.btnTitle} onPress={props.onClick} />
      </View>
    </View>
  );
};

export default LeftMenu;

const styles = StyleSheet.create({
  menuContainer: {
    width: "100%",
    backgroundColor: "#683683",
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn: {
    backgroundColor: "#ffffff5f",
    paddingHorizontal: 10,
    alignItems: "center",
    marginHorizontal: 10,
    justifyContent: "center",
    borderRadius: 5,
    paddingVertical: 5,
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#fff",
  },
  logo: {
    width: 40,
    height: 45,
    resizeMode: "contain",
    marginRight: 20,
  },
  accInfo: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  accName: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#fff",
  },
});
